import React, { useState } from "react";
import YouTube from "react-youtube";
import { getYouTubeID, getYouTubeThumbnail } from "@helper";

import styles from "./video.module.scss";

const Video = props => {
  const [isPlayed, setIsPlayed] = useState(false);

  const handleClick = e => {
    e.target.classList.add("d-none");
    setIsPlayed(true);
  };

  const _onReady = e => {
    e.target.playVideo();
  };

  const YTOpts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 0,
    },
  };

  if (isPlayed) {
    return (
      <>
        <YouTube
          videoId={getYouTubeID(props.link)}
          opts={YTOpts}
          className={styles.video}
          containerClassName={styles.videoContainer}
          onReady={_onReady}
        />
      </>
    );
  } else {
    return (
      <div className={styles.videoContainer} onClick={handleClick}>
        <div className={styles.playButton}></div>
        <img
          src={getYouTubeThumbnail(
            props.link,
            props.quality ? props.quality : "mq"
          )}
          alt="thumbnail"
          className={styles.thumbnail}
          style={props.imgStyle}
        />
      </div>
    );
  }
};

export default Video;
